import styled from "styled-components";

const ItemContainerDark = styled.div`
    display: flex;
    flex-grow: 1;
    text-align: left;
    padding: .5rem .5rem;
    background-color: ${props => props.theme.devgray};
    order: ${props => props.order};
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
`;

export default ItemContainerDark;