import styled from 'styled-components';

const ButtonSimple = styled.button`

    position: relative;

    width: ${ props => props.width ? props.width : '100%' };

    background: white;
    border-color: grey;
    
    border: 0;
    background: none;
    box-shadow: none;
 
    font-size: 1.2rem;
    
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

      filter: drop-shadow(2px 2px 2px rgba(125,125,125,0.90));

    background-color: ${props => props.bisselected ? "orange" : props.theme.darkAccent };

    border-radius: 9px;

    padding: 5px 40px 5px 40px;
    margin: 10px 0 10px 0;

    transition: all .5s ease-in;
    color: white;
    a, a:visited {
        color: black;
        text-decoration: none;
    }
    overflow: hidden;

    z-index: 0;
    
    :before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-color: ${ props => props.theme.darkAccent };
        width: 150%;
        height: 150%;
        z-index: -1;
        transform-origin: bottom left;
        transform: rotate(-90deg);
        transition: all 1s;
    }
    
    &:hover:before {
        transform: rotate(0deg);
    }
     
    &:hover {
        transition: all .5s ease-in;
    }
    
    &:focus {
        outline: none;
    }
    
`;

export default ButtonSimple;