import React from 'react';
import styled from 'styled-components';

const Cen = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ButtonS = styled.button`

    position: relative;
    z-index: 0;

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    @media (max-width: 900px) {
        display: inline-block;
        width: 100%;
        justify-content: center;
    }
            
    background: white;
    border-color: grey;

    border: 0;
    background: none;
    box-shadow: none;
 
    font-size: 1.2rem;
    @media (max-width: 900px) {
        font-size: 1rem;
    }
            
    background-color: ${props => props.bisselected ? "orange" : props.theme.mainBrand};

    border-radius: 9px;

    padding: 1rem 40px 1rem 40px;

    margin: 1rem 0 1rem 0;

    transition: all .5s ease-in;
    color: white;
    a, a:visited {
        color: black;
        text-decoration: none;
    }
    overflow: hidden;

    :before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-color: ${props => props.theme.lightAccent};
        width: 150%;
        height: 150%;
        z-index: -1;
        transform-origin: bottom left;
        transform: rotate(-90deg);
        transition: all .3s;
    }

    filter: drop-shadow(2px 2px 2px rgba(125,125,125,0.90));

    &:hover:before {
        transform: rotate(0deg);
    }

    &:hover {
        color: ${props => props.bisselected ? "orange" : props.theme.darkAccent}
        transition: all .5s ease-in;
    }

    &:focus {
        outline: none;
    }
    
`;

const ImgI = styled.i`
    font-size: 1rem;
    @media (max-width: 900px) {
        font-size: .6rem;
    }
`;

function Button(props) {
    return (
        <Cen>
            <ButtonS
                onClick={props.click}>
                {props.imgClass ? <ImgI><i className={props.imgClass}/></ImgI> : null}
                {props.children}
            </ButtonS>
        </Cen>
    )
}

export default Button;