import React from 'react';
import styled from 'styled-components';
const Around = styled.div`
    margin: 1rem 1rem 1rem 4rem;
    @media (max-width: 1100px) {
        margin: 0 2rem 6rem 2rem;
        padding: 0 0;
    }
    width: 100%;
`;

const Background = styled.div`
    margin: 0rem 1rem 1rem 0rem;
    width: 100%;
x`;

class TechSkillsPM extends React.Component {
    render() {
        return (
            <>
                <Around>
                    <Background>
                        <div className="chip">
                            <img src="/images/fontawsome/linux-brands.svg" alt="Linux Logo"/> Linux
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/jira-brands.svg" alt="Jira"/> Jira
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/jenkins-brands.svg" alt="Jenkins"/> Jenkins
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/azure.svg" alt="Azure"/> Azure
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/confluence-brands.svg" alt="Confluence"/> Confluence
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/aws-brands.svg" alt="AWS"/> AWS
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/docker-brands.svg" alt="Docker"/> Docker
                        </div>
                    </Background>
                </Around>
            </>
        );
    }
}

export default TechSkillsPM;
