import React, {Fragment} from 'react';
import styled from 'styled-components';
import MainColumnWidthContainer from "./MainColumnWidthContainer";
import BrowserWidthContainer from "./BrowserWidthContainer";
import TechSkillsLanguages from "./TechSkillsLanguages";
import TechSkillsArch from "./TechSkillsArch";
import TechSkillsPM from "./TechSkillsPM";

/* http://www.picturetopeople.org/image_effects/photo-lego-toy/lego-toy-photo-effect-editor.html */

const LeftEntry = styled.div`
    flex-basis: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const RightEntry = styled.div`
    flex-basis: 40%;
    justify-content: center;
    align-items: center;
`;

const LeftEntry1 = styled.div`
    flex-basis: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const RightEntry1 = styled.div`
    flex-basis: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const InputContainerTop = styled.div`
    margin: 1rem 0 1rem 0;
    display: flex;
    flex-direction: row;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    
    justify-content: space-between;
`;

class Intro1 extends React.Component {

    render() {
        return (
            <Fragment>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <InputContainerTop>
                        </InputContainerTop>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <InputContainerTop>
                        </InputContainerTop>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <InputContainerTop>
                            <RightEntry1>
                                <p>I have a passion for producing working, usable and well-written software applications which fulfil the client's brief.<br/><br/>
                                    I aim to produce elegant, simple, well-written code with minimal defects.</p>
                            </RightEntry1>
                            <LeftEntry1>
                                <TechSkillsLanguages/>
                            </LeftEntry1>
                        </InputContainerTop>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>


                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <InputContainerTop>
                            <LeftEntry>
                                <TechSkillsArch/>
                            </LeftEntry>
                            <RightEntry>
                                <p>Software development is much more than churning out products and code as quickly as possibly.</p>
                                <p>Once delivered, successful software products can be in production 5 to 10 years after completion.</p>
                            </RightEntry>
                        </InputContainerTop>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <InputContainerTop>
                            <RightEntry1>
                                <p>Project tracking tools, Automated tests, Automatic container deployment and Monitoring, removes several manual layers of potential issues.<br/><br/>
                                These practices allow new functionally to be integrated and rolled out quickly and without incident.</p>
                            </RightEntry1>
                            <LeftEntry1>
                                <TechSkillsPM/>
                            </LeftEntry1>
                        </InputContainerTop>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

            </Fragment>
        );
    }
}

export default Intro1;
