import React, {Fragment} from 'react';
import styled from 'styled-components';
import {populateData} from "../../data/Data";

const Cards = styled.div`
  margin: 2rem 2rem 2rem 2rem;
`;

class TechList extends React.Component {

    constructor() {
        super();
        this.techList = populateData();
    }

    render() {

        let find = [];

        if (this.props.list) {
            find = this.props.list;
        }

        return (
            <Fragment>

                {this.props.title &&
                    <h3>{this.props.title}</h3>
                }

                <Cards>
                    {this.techList
                        .filter(techItem => {
                            return techItem.rating > 0
                        })
                        .filter(techItem => {
                            return techItem.categories.some(r => find.includes(r))
                        })
                        .map((techItem) => (
                            <div className="chip" key={techItem.title}
                                       img={techItem.img}
                            >{techItem.title}&nbsp;&nbsp;&nbsp;

                                { techItem.rating >= 1 &&
                                <span><i className="fal fa-xs fa-solid fa-star"></i></span>}

                                { techItem.rating >= 2 &&
                                    <span><i className="fal fa-xs fa-solid fa-star"></i></span>}

                                { techItem.rating >= 3 &&
                                    <span><i className="fal fa-xs fa-solid fa-star"></i></span>}

                                { techItem.rating >= 4 &&
                                    <span><i className="fal fa-xs fa-solid fa-star"></i></span>}

                                { techItem.rating >= 5 &&
                                    <span><i className="fal fa-xs fa-solid fa-star"></i></span>}

                                { techItem.rating % 1 >= 0.5 &&
                                <i className="fal fa-xs fa-solid fa-star-half"></i>}

                            </div>
                        ))}
                </Cards>

            </Fragment>
        );
    }

}

export default TechList;
