import React from 'react';
import styled from 'styled-components';

const Around = styled.div`
    margin: 1rem 1rem 1rem 4rem;
    @media (max-width: 1100px) {
        margin: 0 2rem 6rem 2rem;
        padding: 0 0;
    }
    width: 100%;
`;

const Background = styled.div`
    margin: 0rem 1rem 1rem 0rem;
    width: 100%;
x`;

class TechSkillsLanguages extends React.Component {

    render() {

        return (
            <>
                <Around>
                    <Background>
                        <div className="chip">
                            <img src="/images/fontawsome/react-brands.svg" alt="React"/> React
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/node-js-brands.svg" alt="Node"/> Node
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/laravel-brands.svg" alt="Laravel"/> Laravel
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/php-brands.svg" alt="PHP"/> PHP
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/js-brands.svg" alt="Javascript"/> JS
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/typescript_logo_2020.svg" alt="Typescript"/> Typescript
                        </div>
                        <div className="chip">
                            <img src="/images/fontawsome/python-brands.svg" alt="Python"/> Python
                        </div>
                        <div className="chip">
                                <img src="/terminal.svg" alt="Laravel"/> Shell
                        </div>
                    </Background>
                </Around>
            </>
        );
    }
}

export default TechSkillsLanguages;
