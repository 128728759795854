import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import logger from 'redux-logger';
import {reducer as formReducer} from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';
import modalsReducer from "./features/modals/modalReducer";
import userReducer from "./reducers/userReducer";

const combinedReducer = combineReducers({
    form: formReducer,
    modals: modalsReducer,
    toastr: toastrReducer,
    user: userReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store = null;

if (process.env.NODE_ENV !== 'production') {
    store = createStore(
        combinedReducer,
        composeEnhancers(applyMiddleware(logger))
    );
} else {
    store = createStore(
        combinedReducer,
        composeEnhancers(applyMiddleware())
    );
}

export default store;
