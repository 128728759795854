import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Link, withRouter} from "react-router-dom";
import ButtonClearLight from "../common/ButtonClearLight";
import {openModal} from "../features/modals/modalActions";
import {connect} from "react-redux";
import AuthHelperMethods from "../components/AuthHelperMethods";
import * as userActions from "../actions/userActions";
import {bindActionCreators} from "redux";
import {toastr} from "react-redux-toastr";

const Container = styled.div`
    display: flex;
    @media (max-width: 900px) {
        flex-direction: column;
    }
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const LinkGloval = ButtonClearLight.withComponent(Link);

class HeaderMenuItems extends React.Component {

    signOut() {
        const Auth = new AuthHelperMethods()
        this.props.userActions.setUserName('')
        toastr.info("You are now signed out");
        this.props.history.push("/");
        Auth.logout()
        this.props.onClick()
    }

    render() {

        const {openModal} = this.props;

        const Auth = new AuthHelperMethods()

        return (
            <Fragment>
                <Container>
                    <LinkGloval to="/" onClick={() => {
                        this.props.onClick()
                    }}>
                        Home
                    </LinkGloval>
                    <LinkGloval to="/portfolio/" onClick={() => {
                        this.props.onClick()
                    }}>
                        Portfolio
                    </LinkGloval>
                    <LinkGloval to="/resume" onClick={() => {
                        this.props.onClick()
                    }}>
                        Resume
                    </LinkGloval>

                    <LinkGloval to="/skills/" onClick={() => {
                        this.props.onClick()
                    }}>
                        Skills
                    </LinkGloval>

                    {!Auth.loggedIn() &&
                    <LinkGloval onClick={() => {
                        this.props.onClick();
                        openModal('LoginModal', {data: 43})
                    }} to="#">
                        Sign In&nbsp;
                        <i className="far fa-sign-in"></i>
                    </LinkGloval>
                    }

                    {Auth.loggedIn() &&
                    <LinkGloval onClick={() => this.signOut()} to="#">
                        Sign Out&nbsp;
                        <i className="far fa-sign-out"></i>
                    </LinkGloval>
                    }
                </Container>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        openModal: bindActionCreators(openModal, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderMenuItems));
