import React from 'react';
import styled from 'styled-components';

const Around = styled.div`
    margin: 1rem 1rem 1rem 4rem;
    @media (max-width: 1100px) {
        margin: 0 2rem 6rem 2rem;
        padding: 0 0;
    }
    width: 100%;
`;

const Background = styled.div`
    margin: 0rem 1rem 1rem 0rem;
    width: 100%;
x`;

class TechSkillsArch extends React.Component {

    render() {

        return (
            <>
                <Around>
                    <Background>
                        <div className="chip">
                            Agile
                        </div>
                        <div className="chip">
                            <img src="/agile.svg" alt="Laravel"/> Scrum
                        </div>
                        <div className="chip">
                            TDD
                        </div>
                        <div className="chip">
                            Unit Tests
                        </div>
                        <div className="chip">
                            Integration Tests
                        </div>
                        <div className="chip">
                            End 2 end Tests
                        </div>
                    </Background>
               </Around>
            </>
        );
    }
}

export default TechSkillsArch;
