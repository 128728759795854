import React from 'react';
import styled from 'styled-components';
import {stack as Menu} from 'react-burger-menu';
import HeaderMenuItems from "./HeaderMenuItems";

const Container = styled.div`
`;

class Header extends React.Component {

    state = {
        menuOpen: false,
    }

    showSettings = () => {
        // event.preventDefault()
        this.setState( state => ( {menuOpen: false}) )
    }

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }

    render() {
        return (
            <Container>
                <Menu {...this.props}
                      isOpen={ this.state.menuOpen }
                      onStateChange={(state) => this.handleStateChange(state)}
                >
                    <HeaderMenuItems onClick={ this.showSettings }/>
                </Menu>
            </Container>
        );
    }

}

export default Header;
