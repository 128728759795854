import React from 'react';
import styled from 'styled-components';
import {Link} from "react-router-dom";
//import Link from "react-router-dom/es/Link";

const Nostyle = styled.a`
    text-decoration: none;
    display: flex;
    flex-flow: column;
    padding: 1rem 1rem 1rem 1rem;
    margin: 1rem 0rem 1rem 0rem;
    transition: all .3s ease-in-out;
    border: 1px solid ${props => props.theme.lightAccent};

    :hover {
        background-color: ${props => props.link !== '' ? `rgba(128, 128, 128, 0.2)` : `` };
    text-decoration: none;
    }
    align-items: center;
    cursor: ${props => props.link !== '' ? `pointer` : `` };
`;

const Imgicon = styled.img`
  font-size: 5rem;
  max-width: 125px;
  max-height: 125px;
  width: auto;
  height: auto;  
`;

const Fonp = styled.p`
  font-size: .9rem;
  line-height: 1.3rem;
  width: 100%;
  padding: 1rem 0 0 0;
  color: black;
`;

const LinkGloval = Nostyle.withComponent(Link);

class HoverButton extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            bselected1: ''
        }
    }

    onClick = () => {
        this.setState({
            bselected: !this.state.bselected
        })
        if (this.props.link !== "") {
            window.location = this.props.link;
        }
    }

    render() {

        // let aLink = "";
        // if (typeof this.props.link == "string") {
        //     aLink = this.props.link;
        // };

        return (
            <LinkGloval to={this.props.link}>
                {this.props.src &&
                <Imgicon src={this.props.src}></Imgicon>
                }

                <Fonp>{this.props.children}</Fonp>
            </LinkGloval>
        );
    }
}

export default HoverButton;
