import styled from "styled-components";

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    min-height: ${props => props.minHeightInPercent};
    text-align: left;
    border-radius: 4px;
    // border: 1px solid red;
`;

export default ItemContainer;