import React, {Fragment} from 'react';

import {BrowserRouter, Route, Switch} from 'react-router-dom';
import posed, {PoseGroup} from 'react-pose';
import Index from "./pages/Index.js";

import Skills from "./pages/Skills.js";
import Ability from "./pages/Ability.js";

import Resume from "./pages/Resume.js";
import Portfolio from "./pages/Portfolio.js";

import Header from "./containers/Header.js";
import styled, {ThemeProvider} from "styled-components";
import ModalManager from './features/modals/ModalManager.js'
import "./burgerStyles.css"
import "./menuStyles.css"
import ScrollMagic from "scrollmagic";
import HeaderBar from "./containers/HeaderBar.js";

import "react-image-gallery/styles/css/image-gallery.css";
import HeroMiniSkills from "./containers/HeroMiniSkills.js";
import NoPage from "./pages/NoPage.js";
import {theme} from "./theme";

const RouteContainer = posed.div({
    enter: {opacity: 1, delay: 100},
    exit: {opacity: 0}
});

const ShowOnDesktop = styled.div`
    visibility: hidden;
    @media (max-width: 900px) {
        visibility: visible;
    }
`;

const Container = styled.div`
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    .showit {
        visibility: visible;
        height: 5rem;
        transition: all .1s ease-in;
    }
    
    .fixedat {
        position: absolute;
        top: 55vh;
    }
    
    .mdImage {
        padding: 1rem 1rem;
    }
    
    .mdImageShapeOutside {
        shape-outside: circle();
    }
    
    img {
        transform: rotate(0deg);
      filter: drop-shadow(2px 2px 2px rgba(125,125,125,0.90));
    }

    .tiltright1 {
        transform: rotate(1deg);
        transition: all .5s ease-in;

        &:hover {
            transform: rotate(-1deg);
            transition: all .5s ease-in;
        }
    }

    .tiltright2 {
        transform: rotate(2deg);
        transition: all .5s ease-in;

        &:hover {
            transform: rotate(-2deg);
            transition: all .5s ease-in;
        }
    }

    .tiltright3 {
        transform: rotate(3deg);
        transition: all .5s ease-in;

        &:hover {
            transform: rotate(-3deg);
            transition: all .5s ease-in;
        }
    }

    .tiltleft1 {
        transform: rotate(-1deg);
        transition: all .5s ease-in;

        &:hover {
            transform: rotate(1deg);
            transition: all .5s ease-in;
        }
    }

    .tiltleft2 {
        transform: rotate(-2deg);
        transition: all .5s ease-in;

        &:hover {
            transform: rotate(2deg);
            transition: all .5s ease-in;
        }
    }

    .tiltleft3 {
        transform: rotate(-3deg);
        transition: all .5s ease-in;

        &:hover {
            transform: rotate(3deg);
            transition: all .5s ease-in;
        }
    }

   .chip {
      margin: 1rem 40px 0 -25px;
      display: inline-block;
      padding: 0 25px;
      height: 50px;
      font-size: 17px;
      line-height: 50px;
      border-radius: 25px;
      background-color: ${theme.lightAccent};
      color: black;
   }

   .chip img {
      float: left;
      margin: 0 10px 0 -23px;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      filter: drop-shadow(2px 2px 2px rgba(125,125,125,0.70))
   }
    
`;

class AppRouter extends React.Component {

    constructor(props) {
        super(props);
        this.controller = new ScrollMagic.Controller();
    }

    componentDidMount() {
        new ScrollMagic.Scene({
            triggerElement: "#triggerShowStickyMenu"
        })
            .setClassToggle('#header', 'showit')
            .addTo(this.controller); // assign the scene to the controller
    }

    render() {

        return (
            <Fragment>

                <Container>
                    <BrowserRouter>
                        <Route
                            render={({location}) => (
                                <ThemeProvider theme={theme}>
                                    <Fragment>
                                        <ShowOnDesktop>
                                            <Header pageWrapId={"page-wrap"} outerContainerId={"App"}/>
                                        </ShowOnDesktop>
                                        <ModalManager style={{zIndex: 4}}/>
                                        <HeaderBar sticky={true}/>
                                        <HeaderBar sticky={false}/>
                                        <RouteContainer key={location.pathname}>
                                            <Switch>
                                                <Route exact path="/">
                                                </Route>
                                                <Route>
                                                    <HeroMiniSkills/>
                                                </Route>
                                                <Route path="*" component={NoPage} key="NoPage"/>
                                            </Switch>
                                        </RouteContainer>

                                        <div className="fixedat" id="triggerShowStickyMenu"></div>

                                        <PoseGroup>
                                            <RouteContainer key={location.pathname}>
                                                <Switch location={location}>
                                                    <Route exact path="/" component={Index} key="Index"/>
                                                    <Route path="/skills" component={Skills} key="Skills"/>
                                                    <Route path="/ability" component={Ability} key="Ability"/>
                                                    <Route exact path="/portfolio" component={Portfolio}
                                                           key="Portfolio2"/>
                                                    <Route path="/portfolio/:pagename" component={Portfolio}
                                                           key="Portfolio3"/>
                                                    <Route path="/resume" component={Resume} key="Resume"/>
                                                    <Route path="*" component={NoPage} key="NoPage"/>
                                                </Switch>
                                            </RouteContainer>
                                        </PoseGroup>
                                    </Fragment>
                                </ThemeProvider>
                            )}
                        />
                    </BrowserRouter>
                </Container>
            </Fragment>
        )
    }
}

export default (AppRouter);
