import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {prodDev} from "../library/prodDev";
import TsButton from "../components/TsButton";

const FloatTopRight = styled.div`
    position: fixed;
    top: 5rem;
    right: 10rem;
    display: flex;
    z-index: 19;
`;

const MonitorClick = (props) => {

    const [visible, setVisible] = useState(false);

    const url = prodDev.getUrlWys() + "/" + props.page

    const handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') {
            setVisible(prevState => !prevState);
        }
        let escCode = 27;
        if (e.keyCode === escCode) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown);
    }, []);

    const onOpenLink = () => {
        window.open(url , '_blank');
        setVisible(false);
    }

    const onClose = () => {
        setVisible(false);
    }

    return (
        <>
            {visible &&
                <FloatTopRight>
                    <TsButton
                        imgClass="fal fa-2x fa-pencil"
                        onOpenLinkClick={onOpenLink}
                        onCloseClick={onClose}
                    >
                        Edit Page (Ctr+z)
                    </TsButton>
                </FloatTopRight>
            }
        </>
    )

}

export default MonitorClick;
