import React, {FunctionComponent} from 'react';
import styled from "styled-components";

type Props = {
    imgClass: string,
    title: string,
    paragraph: string,
    onOpenLinkClick: () => void,
    onCloseClick: () => void,
    children: React.ReactNode
}

const PushButtonS = styled.div`
    cursor: pointer;
    background: white;
    border: 1px solid green;

    font-size: 1.2rem;
    @media (max-width: 900px) {
        font-size: 1rem;
    }

    border-radius: 9px;
    padding: 1rem 40px 1rem 40px;
    margin: 1rem 0 1rem 0;
    box-shadow: none;

    background-color: green;
    text-align: center;
    transition: all .3s ease-in;
    color: white;

    a, a:visited {
        text-decoration: none;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.9);
        color: black;
    }

    filter: drop-shadow(2px 2px 2px rgba(125,125,125,0.90));
`;

const AlignVertical = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0rem 1.5rem 0rem 0rem;
`;

const ImgI = styled.i`

    margin: 0rem 2rem 0rem .4rem;
`;

const ImgTopRight = styled.i`
     position: absolute;
     right: .5rem;
     top: .5rem;
 `;

// we can use children even though we haven't defined them in our CardProps
export const TsButton: FunctionComponent<Props> = (props) => {
    return (
        <div>
            <PushButtonS>
                <AlignVertical onClick={props.onOpenLinkClick}>
                    {props.imgClass ?
                        <ImgI className={props.imgClass}></ImgI>
                        :
                        null}
                    {props.children}
                </AlignVertical>
                <ImgTopRight
                    onClick={props.onCloseClick}
                    className="fa fa-window-close"
                    aria-hidden="true"></ImgTopRight>
            </PushButtonS>
        </div>
    )
}

export default TsButton;

