import React, { Fragment } from 'react';
import styled from 'styled-components';

const Background = styled.div`
    color: black;

    width: 100%;
    
    height: 2rem;

    text-align: center;
    padding: 2rem 0rem 0rem 0rem;PRO

    display: flex;
    
    flex-direction: row;
    @media (max-width: 700px) {
        flex-direction: column;
    }

    justify-content: center;
    align-items: center;

`;

const Item = styled.div`
            font-family: 'Monospace', sans-serif;
    margin: 0rem 0rem 0rem rem;
    padding: 0rem 0rem 2rem 0rem;
`;

class Footer extends React.Component {

    render() {
        return (
            <Fragment>
                <Background>
                    <Item>
                        &copy; 2022 Website & Theme built by Jon Leahy, <i className="fal fa-1x fa-envelope"/> jonathanl@newtimesystems.com (10-04-22 23-50)
                    </Item>
                    <br/>
                    <br/>
                </Background>
            </Fragment>
        );
    }
}

export default Footer;
