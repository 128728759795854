import React from 'react';
import styled from 'styled-components';

const StyleMain = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.br !== "" ? props.br : ""};
    height: ${props => props.height !== "" ? props.height : ""};
`;

class BrowserWidthContainer extends React.Component {

    render() {
        return (
            <StyleMain img={this.props.img} height={this.props.height} br={this.props.br}>
                {this.props.children}
            </StyleMain>
        );
    }
}

export default BrowserWidthContainer;