import React from 'react';
import { hydrate, render } from "react-dom";

import './index.css';
import AppRouter from './AppRouter.js';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import store from "./store";
import ReduxToastr from "react-redux-toastr";

const rootElement = document.getElementById("root");

// system is compiled before publishing so there are two methods of displaying
if (rootElement.hasChildNodes()) {
    hydrate(
        <Provider store={store}>
            <ReduxToastr
                position='top-left'
                transitionIn='fadeIn'
                transitionOut='fadeOut'
                preventDuplicates
            />
            <AppRouter />,
        </Provider>
        , rootElement);
} else {
    render(
        <Provider store={store}>
            <ReduxToastr
                position='top-left'
                transitionIn='fadeIn'

                transitionOut='fadeOut'
                preventDuplicates
            />
            <AppRouter />,
        </Provider>
        , rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();
