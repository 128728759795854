import React, {Fragment} from 'react';
import IntroBlock from "../containers/IntroBlock";
import Footer from "../containers/Footer";
import TechList from "../dev/pages_dev/TechList";
import posed from "react-pose";
import BrowserWidthContainer from "../containers/BrowserWidthContainer";
import MainColumnWidthContainer from "../containers/MainColumnWidthContainer";
import styled from "styled-components";
import StickyBox from "react-sticky-box";
import SideTechBlock from "../containers/SideTechBlock";
import SideTechBlockGeneral from "../containers/SideTechBlockGeneral";
import SideTechBlockPHP from "../containers/SideTechBlockPHP";
import Button from "../common/Button";

const Top = posed.div({
    enter: { x: 0, opacity: 1, delay: 0 },
    exit: {
        x: 150,
        opacity: 0,
        transition: { duration: 100 }
    }
});

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 700px) {
        width: 100%;
        flex-direction: column-reverse;
    }
`;

const LeftEntry = styled.div`
    flex-basis: 80%;
`;

const RightEntry = styled.div`
    flex-basis: 20%;
    display: flex;
    flex-direction: column;
    justify-contents: space-between;
    height: 100%;
    padding: 0rem 2rem 2rem 1rem;
    @media (max-width: 700px) {
        padding: 0rem 0rem 2rem 0rem;
    }
`;

const InputContainerTop = styled.div`
    margin: 0rem 0 2rem 0;
    display: flex;
    flex-direction: row;
    @media (max-width: 700px) {
        flex-direction: column;
    }
       justify-content: space-between;
`;

const DesktopOnly = styled.div`
    display: flex;
    @media (max-width: 700px) {
        display: none;
    }
`;

class Skills extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            scrollTop: 0,
            filtername: ''
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.scrollTop !== 0) {
            this.setState({scrollTop: nextProps.scrollTop});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {scrollTop} = this.state;
        if (scrollTop !== prevState.scrollTop) {
            window.scrollTo(0, 0)
        }
    }

    filter(filtertext) {
        this.setState({
            filtername: filtertext
        })
    }

    render() {

        return (

            <Top>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <InputContainerTop>
                        </InputContainerTop>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>

                        <InputContainer>
                            <LeftEntry>
                                <IntroBlock order="1" img="" imgwidth="" backgroundsize="contain" title="">
                                    <br/>
                                    <h2>Skills<br/><br/></h2>
                                    {this.state.filtername !== "" &&
                                    <TechList title={this.state.filtername} list={[this.state.filtername]}/>
                                    }

                                    {this.state.filtername === "" &&
                                    <Fragment>
                                        <TechList title={"Core"} list={["GENERAL"]}/>
                                        <br/>
                                        <TechList title={"More"} list={["OTHERGENERAL"]}/>
                                        <br/>
                                        <TechList title={"Other"} list={["HAVEUSED"]}/>
                                        <br/>
                                        <br/>
                                    </Fragment>
                                    }
                                </IntroBlock>
                            </LeftEntry>

                            <RightEntry>

                                <StickyBox offsetTop={120}>
                                    <p>Filter the Skillset here:</p>
                                    <Button click={() => {
                                        this.setState({filtername: ""})
                                    }}>
                                        All
                                    </Button>

                                    <Button click={() => {
                                        this.setState({filtername: "JAVASCRIPT"})
                                    }}>JavaScript</Button>

                                    <Button click={() => {
                                        this.setState({filtername: "PHP"})
                                    }}>PHP</Button>

                                    {this.state.filtername === "PHP" &&
                                    <DesktopOnly>
                                        <SideTechBlockPHP/>
                                    </DesktopOnly>
                                    }

                                    {this.state.filtername === "" &&
                                    <DesktopOnly>
                                        <SideTechBlockGeneral/>
                                    </DesktopOnly>
                                    }

                                    {this.state.filtername === "JAVASCRIPT" &&
                                    <DesktopOnly>
                                        <SideTechBlock/>
                                    </DesktopOnly>
                                    }

                                </StickyBox>

                            </RightEntry>

                        </InputContainer>

                    </MainColumnWidthContainer>

                </BrowserWidthContainer>

                <Footer/>

            </Top>

        );
    }

}

export default Skills;
