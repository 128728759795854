import React from 'react';
import { connect } from 'react-redux';
import TestModal from './TestModal';
import LoginModal from './LoginModal';
import MoreDetails1Modal from './MoreDetails1Modal';

const modalLookup = {
    TestModal,
    LoginModal,
    MoreDetails1Modal
};

const mapState = (state) => ({
    currentModal: state.modals
});

const ModalManager = ({currentModal}) => {
    let renderedModal;
    if (currentModal) {
        const {modalType, modalProps} = currentModal;
        const ModalComponent = modalLookup[modalType];
        renderedModal = <ModalComponent {...modalProps} />
    }
    return <span>{renderedModal}</span>
};

export default connect(mapState)(ModalManager);