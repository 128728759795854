import React from 'react';
import styled from 'styled-components';
import MainColumnWidthContainer from "./MainColumnWidthContainer";
import posed from "react-pose";

import BrowserWidthContainer from "./BrowserWidthContainer";

const Background = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    width: 100%;
    justify-content: space-evenly;
`;

const Block = styled.div`
    flex-grow: 1;
    flex-basis: 0;
    i {
        text-shadow: 10px 10px 17px rgba(0,0,0,0.09);
        color: ${props => props.theme.mainBrand};
    }
`;

const Block2 = styled.div`
    position: relative;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0rem 1.5rem 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    i {
       width: auto;
    }
    height: 100%;
`;

const Sig1 = styled.p`
    margin: 1rem 1.5rem 1rem 1.5rem;
    @font-face {
        font-family: 'James Fajardo';
        src: url('/james-fajardo/James Fajardo.ttf');
        font-display: swap;
    }
    font-family: 'James Fajardo', 'Georgia', serif;
    font-size: 3.2rem;
    line-height: 2.5rem;
`;

const StyledBadge = styled.div`
`;

const SmallSquare = posed.div({
    idle: {scale: 1, opacity: 1},
    hovered: {scale: 1.05, opacity: 1}
});

const Square = posed.div({
    idle: {scale: 1},
    hovered: {scale: 1.5}
});

const StyledSquare = styled(Square)`
`;

const StyledSquare2 = styled(SmallSquare)`
`;

class MasterSkills extends React.Component {
    state = {state: [
        {hovering1: false},
        {hovering2: false},
        {hovering3: false},
    ]};

    render() {
        return (
            <BrowserWidthContainer>
                <MainColumnWidthContainer>
                    <Background>
                        <Block>
                            <StyledBadge>
                                <Block2>
                                <StyledSquare
                                    pose={this.state.hovering1 ? "hovered" : "idle"}
                                    onMouseEnter={() => this.setState({hovering1: true})}
                                    onMouseLeave={() => this.setState({hovering1: false})}
                                >
                                    <img src={"/images/fontawsome/user-friends.svg"} height={'100px'} width={'100px'}
                                         alt="Discuss"/>
                                </StyledSquare>
                                </Block2>
                            </StyledBadge>
                            <Block2>
                                <StyledSquare2
                                    pose={this.state.hovering1   ? "hovered" : "idle"}
                                    onMouseEnter={() => this.setState({hovering1: true})}
                                    onMouseLeave={() => this.setState({hovering1: false})}
                                >
                                <p>Misunderstanding and incomplete details at the requirements gathering stage means
                                    potentially implementing the wrong solution, which will lead to a higher delivery failure rate.</p>
                                </StyledSquare2>
                            </Block2>
                        </Block>
                        <Block>
                            <StyledBadge>
                                <Block2>
                                <StyledSquare
                                    pose={this.state.hovering2 ? "hovered" : "idle"}
                                    onMouseEnter={() => this.setState({hovering2: true})}
                                    onMouseLeave={() => this.setState({hovering2: false})}
                                >
                                    <img src={"/images/fontawsome/code.svg"} height={'100px'} width={'100px'}
                                         alt="Build"/>
                                </StyledSquare>
                                </Block2>
                            </StyledBadge>
                            <Block2>
                                <StyledSquare2
                                    pose={this.state.hovering2   ? "hovered" : "idle"}
                                    onMouseEnter={() => this.setState({hovering2: true})}
                                    onMouseLeave={() => this.setState({hovering2: false})}
                                >
                                <p>It takes skill and experience to design and build low maintenance, readable and resilient software products that will need to accommodate change.</p>
                                </StyledSquare2>
                            </Block2>
                        </Block>
                        <Block>
                            <StyledBadge>
                                <Block2>
                                <StyledSquare
                                    pose={this.state.hovering3 ? "hovered" : "idle"}
                                    onMouseEnter={() => this.setState({hovering3: true})}
                                    onMouseLeave={() => this.setState({hovering3: false})}
                                >
                                    <img src={"/images/fontawsome/flag-checkered.svg"} height={'100px'} width={'100px'}
                                         alt="Finish"/>
                                </StyledSquare>
                                </Block2>
                            </StyledBadge>
                            <Block2>
                                <StyledSquare2
                                    pose={this.state.hovering3   ? "hovered" : "idle"}
                                    onMouseEnter={() => this.setState({hovering3: true})}
                                    onMouseLeave={() => this.setState({hovering3: false})}
                                >
                                <p>Clients' primary objectives are deadlines, budgets, and product delivery that meets their expectations.</p>
                                </StyledSquare2>
                            </Block2>
                        </Block>
                    </Background>

                    <Background>
                        <Block>
                            <Block2>
                                <StyledSquare2
                                    pose={this.state.hovering1 ? "hovered" : "idle"}
                                    onMouseEnter={() => this.setState({hovering1: true})}
                                    onMouseLeave={() => this.setState({hovering1: false})}
                                >
                                    <i className="fas fa-5x fa-check" alt="Tick-mark"></i>
                                </StyledSquare2>
                            </Block2>
                        </Block>
                        <Block>
                            <Block2>
                                <StyledSquare2
                                    pose={this.state.hovering2 ? "hovered" : "idle"}
                                    onMouseEnter={() => this.setState({hovering2: true})}
                                    onMouseLeave={() => this.setState({hovering2: false})}
                                >
                                    <i className="fas fa-5x fa-check" alt="Tick-mark"></i>
                                </StyledSquare2>
                            </Block2>
                        </Block>
                        <Block>
                            <Block2>
                                <StyledSquare2
                                    pose={this.state.hovering3 ? "hovered" : "idle"}
                                    onMouseEnter={() => this.setState({hovering3: true})}
                                    onMouseLeave={() => this.setState({hovering3: false})}
                                >
                                    <i className="fas fa-5x fa-check" alt="Tick-mark"></i>
                                </StyledSquare2>
                            </Block2>
                        </Block>
                    </Background>

                    <Background>
                        <Block>
                            <p></p>
                        </Block>
                    </Background>

                    <Background>
                        <Block>
                            <StyledSquare2
                                pose={this.state.hovering1 ? "hovered" : "idle"}
                                onMouseEnter={() => this.setState({hovering1: true})}
                                onMouseLeave={() => this.setState({hovering1: false})}
                            >
                            <Sig1>
                                `I have practical experience in exploring, gathering and interpreting requirements.`
                            </Sig1>
                            </StyledSquare2>
                        </Block>
                        <Block>
                            <StyledSquare2
                                pose={this.state.hovering2   ? "hovered" : "idle"}
                                onMouseEnter={() => this.setState({hovering2: true})}
                                onMouseLeave={() => this.setState({hovering2: false})}
                            >
                            <Sig1>
                                `I have significant development experience and practical training in the latest technologies.`
                            </Sig1>
                            </StyledSquare2>
                        </Block>
                        <Block>
                            <StyledSquare2
                                pose={this.state.hovering3 ? "hovered" : "idle"}
                                onMouseEnter={() => this.setState({hovering3: true})}
                                onMouseLeave={() => this.setState({hovering3: false})}
                            >
                            <Sig1>
                                `I have many years of experience designing and delivering systems that meet these key customers' objectives.`
                            </Sig1>
                            </StyledSquare2>
                        </Block>
                    </Background>

                    <Background>
                        <Block>
                        </Block>
                    </Background>

                </MainColumnWidthContainer>
            </BrowserWidthContainer>

        );
    }
}

export default MasterSkills;
