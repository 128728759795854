import initialState from './initialState';
import {USER_LOGIN, USER_LOGOUT} from '../actions/allActions';

export default function user(state = initialState.user, action) {

    //c1onsole.log(state, action)

    let newState;

    switch (action.type) {

        case USER_LOGIN:
            //c1onsole.log("setviewmode")
            newState = {...state, name: action.payload, abc: true}
            return newState;

        case USER_LOGOUT:
            //c1onsole.log("seteditormode")
            newState = {...state, name: 'asss', abc: false}
            return newState;

        default:
            newState = {...state}
            return newState ;

    }

}