import React, { Suspense } from 'react';
import styled from 'styled-components';
const MatterStep = React.lazy(() => import('../components/MatterStep'));

let Container = styled.div`
    position: relative;
`

let StyleMain1 = styled.div`
    width: 100%;

    height: ${props => props.height !== "" ? props.height : ""};
    
    @media (max-width: 900px) {
        height: ${props => props.scroll === true ? "99.7vh" : ""};
    }

    @media (min-width: 900px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    background-attachment: ${props => props.scroll === true ? "fixed" : ""};
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    
    z-index: -9;
`;

class BrowserWidthContainerDark extends React.Component {
    render() {
        return (
            <Container>
                <StyleMain1 className="box" img={''} height={this.props.height} scroll={this.props.scroll}>
                    {this.props.showBounce &&
                        <Suspense fallback={<div>Loading...</div>}>
                        <MatterStep className="box" height={this.props.height} width={'100%'} height={'100%'}></MatterStep>
                        </Suspense>
                    }
                    {this.props.children}
                </StyleMain1>
            </Container>
        );
    }
}

export default BrowserWidthContainerDark;