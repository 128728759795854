import React, {Fragment} from 'react';
import styled from 'styled-components';
import MainColumnWidthContainer from "./MainColumnWidthContainer";
import BrowserWidthContainer from "./BrowserWidthContainer";

const LeftEntry1 = styled.div`
    flex-basis: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RightEntry1 = styled.div`
    flex-basis: 40%;
`;

const InputContainerTop = styled.div`
    margin: 0rem 0 2rem 0;
    
    display: flex;
    flex-direction: row;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    
    justify-content: space-between;
`;

const Sig = styled.div`
    width: 100%;
    @font-face {
        font-family: 'James Fajardo';
        src: url('/james-fajardo/James Fajardo.ttf');
        font-display: swap;
    }
    text-align: right;
    font-family: 'James Fajardo', 'Georgia', serif;
    font-size: 6rem;
    margin: 0rem 0rem 3rem 0rem;
`;

const Sig1 = styled.p`
    @font-face {
        font-family: 'James Fajardo';
        src: url('/james-fajardo/James Fajardo.ttf');
        font-display: swap;
    }
    text-align: right;
    font-family: 'James Fajardo', 'Georgia', serif;
    font-size: 3.2rem;
    line-height: 4rem;
    margin: 3rem 0rem;
`;

const ImgCircle = styled.img`
    border-radius: 90%;
    width: 175px;
    height: 175px;
    @media (max-width: 700px) {
        visible: hidden;
        flex-direction: column;
        width: 75px;
        height: 75px;
    }
    }
`;

class Intro extends React.Component {

    render() {
        return (

            <Fragment>


                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <InputContainerTop>
                        </InputContainerTop>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <LeftEntry1>
                            <ImgCircle src={"/images/jon.png"} alt={'Jon'}></ImgCircle>
                        </LeftEntry1>
                        <RightEntry1>
                            <p>I have many years of software development experience and have worked to stringent quality
                                guidelines since my first professional job.</p>
                            <p>Having a thirst for realising actual products from ideas means I've implemented and
                                worked within all areas of Product Development, including DevOps.</p>
                        </RightEntry1>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <InputContainerTop>
                        </InputContainerTop>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <Sig1>`I find the challenges of building usable software products and keeping up to date
                            stimulating and ultimately rewarding.`</Sig1>
                        <Sig>Jon.Leahy</Sig>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <InputContainerTop>
                        </InputContainerTop>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <InputContainerTop>
                        </InputContainerTop>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

            </Fragment>
        );
    }
}

export default Intro;
