import React from 'react';
import styled from 'styled-components';
import HoverButton from "../common/HoverButton";

const Background = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    justify-content: space-around;    
    padding: .2rem .2rem .2rem .2rem;
`;

const Card = styled.div`
    flex: 0 0 100%;
`;

class SideTechBlock extends React.Component {
    render() {
        return (
            <Background>
                <Card>
                    <HoverButton src="/php.png" link="/skills">
                        PHP: Hypertext Preprocessor (or simply PHP) is a general-purpose programming language originally designed for web development.
                    </HoverButton>
                </Card>
                <Card>
                    <HoverButton src="/laravel.png" link="/skills">
                        Laravel is an open-source PHP web framework, intended for the development of web applications following the model–view–controller (MVC) architectural pattern.
                    </HoverButton>
                </Card>
            </Background>
        );
    }
}

export default SideTechBlock;
