import React from 'react';
import styled from 'styled-components'
import {Draggable} from "react-beautiful-dnd";
import TaskContent from "./taskcontent";
import {openModal} from "../../../components/Modals/modalActions";
import {connect} from "react-redux";

const Container = styled.div`
  border: ${props => (props.showEditor ? '1px solid lightgrey' : '')};
  border-radius: 2px;
  display: flex;
  flex: ${props => props.flex};
  
  width: ${props => props.widthInPercent};
    @media (max-width: 600px) {
  width: 100%;
}

  position: relative;  
`;

const Handle = styled.div`
    opacity: 0.2;
    width: 40px;
    height: 40px;
    background-color: black;           
    border-radius: 4px;
    margin-right: 8px;
    min-width: 25px;
    position: absolute;
    top: 7px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: ${props => (props.showEditor ? ((props.taskid[0] !== '-') ? 'visibility' : 'hidden') : 'hidden')};
`

const Edit = styled.button`
    opacity: 0.2;
    width: 40px;
    height: 40px;
    background-color: black;           
    border-radius: 4px;
    margin-right: 8px;
    min-width: 25px;
    position: absolute;
    top: 7px;
    right: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: ${props => (props.showEditor ? ((props.taskid[0] !== '-') ? 'visibility' : 'hidden') : 'hidden')};
`

const actions = {openModal}

class Task extends React.Component {

    render() {

        const {openModal} = this.props;
        const showEditor = this.props.showEditor

        const width = this.props.task.width ? this.props.task.width : ''
        const flex = this.props.task.width  ? '' : '1'

        const minHeight = this.props.task.height ? this.props.task.height : '50px'

        return (
            <Draggable
                draggableId={this.props.task.id}
                index={this.props.index}
            >
                {(provided, snapshot) => (
                    <Container
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                        showEditor={showEditor}
                        widthInPercent={width}
                        flex={flex}
                        minHeightInPercent={minHeight}
                    >
                        <Handle {...provided.dragHandleProps} showEditor={showEditor} taskid={this.props.task.id}>
                            <i className="far fa-arrows"></i>
                        </Handle>

                        <Edit className="btn btn-primary" type="button"
                              data-target="#deliverable-add" data-toggle="collapse"
                              aria-expanded="false" aria-controls="deliverable-add"
                              showEditor={showEditor} taskid={this.props.task.id}
                              onClick={() => openModal('EditTaskModal', {taskId: this.props.task.id}
                              )}
                        >
                            <i className="far fa-pencil-alt"></i>
                        </Edit>

                        <TaskContent content={this.props.task.content} type={this.props.task.type}
                                     task={this.props.task}
                                     bImagesOnly={this.props.bImagesOnly}
                        />
                    </Container>
                )
                }
            </Draggable>
        )
    }
}

export default connect(null, actions)(Task);
