import React from 'react';
import styled from 'styled-components';
import InfoCard from "./InfoCard";

const Container = styled.div`
    margin: 0rem 0 2rem 0;
`;

const FontD = styled.div`
    flex: 0 0 ${props => props.imwidth !== "" ? props.imwidth : "320px"};
    order: ${props => props.order ==="1" ? "1" : "0"};
`;

const FontE = styled.div`
    flex-grow: 1;
    flex-basis: auto;
    color: black;
    padding: 0rem 0rem 0rem 0rem;
    p {
        text-align: justify;
    }
`;

class IntroBlock extends React.Component {

    render() {

        const order = this.props.order;
        const img = this.props.img;

        const imgwidth = this.props.img !== "" ? this.props.imgwidth : "";

        const backgroundsize = this.props.backgroundsize;
        const title = this.props.title;

        return (
            <Container>
                {imgwidth !== "" &&
                    <FontD order={order} imwidth={this.props.imgwidth}>
                        <InfoCard
                            title={title}
                            order={order}
                            img={img}
                            backgroundsize={backgroundsize}
                        >
                        </InfoCard>
                    </FontD>
                }

                <FontE>
                    {this.props.children}
                </FontE>

            </Container>
        );
    }
}

export default IntroBlock;