import React from 'react';
import styled from 'styled-components';

const theme = {
    lightShade: '#FFF',
    lightAccent: '#C1C9EC',
    mainBrand: '#608EEF',
    darkAccent: '#0e0c7d',

    darkShades: '#000019', // = 0, 0, 25
    darkShadesRGB: 'rgba(255, 255, 255, .9)',
    darkShadesRGBOne: 'rgba(255, 255, 255, .1)',
    darkShadesRGBClear: 'rgba(255, 255, 255, .85)',
    transparent: 'rgba(0, 0, 0, 0)',

    black: 'black'
};

const Container1 = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    width: 100%;
    height: 17rem;

    @media (max-width: 900px) {
        height: 7rem;
    }
      
    // background-color: ${props => props.theme.lightShade};
            
    // background: linear-gradient(
    //           to bottom,
    //           ${props => props.theme.lightShade},
    //           rgba(255, 255, 255, 0.1)
    // ), url();

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    position: relative;
    @media (max-width: 900px) {
        top: 0rem;
    }
    @media (max-width: 1100px) {
        top: -0rem;
    }
`;

const LeftRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
      margin-top: .5rem;
      margin-bottom: 1.5rem;
    }
    h2, h3, h4, h5, h6 {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
    p {
        align-text: center;
    }
`;

const DesktopH1 = styled.h1`
  padding: 1rem 4rem;
    display: block;
    @media (max-width: 900px) {
        display: none;
    }
  background-image: linear-gradient(to left, ${props => theme.black}, ${props => theme.black}, ${props => theme.black}, ${props => theme.black}, ${props => theme.darkAccent}, ${props => theme.darkAccent}, ${props => theme.mainBrand});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const MobileH1 = styled.h1`
    display: none;
    font-size: 2rem;
    font-weight: 400;
    @media (max-width: 900px) {
        display: inline-block;
        padding 4.2rem 0 0rem 0;
    }
  background-image: linear-gradient(to left, ${props => theme.black}, ${props => theme.black}, ${props => theme.black}, ${props => theme.black}, ${props => theme.darkAccent}, ${props => theme.darkAccent}, ${props => theme.mainBrand});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Virtcentered = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 25vh;
`;

const ShowOnDesktop = styled.div`
    position: relative;
    visibility: visible;
    @media (max-width: 900px) {
        width: 100%;
        visibility: hidden;
        position: absolute;
    }
`;

const H4 = styled.h4`
  padding 0rem 4rem;
  background-image: linear-gradient(to right, ${props => theme.black}, ${props => theme.black}, ${props => theme.black}, ${props => theme.black}, ${props => theme.darkAccent}, ${props => theme.darkAccent}, ${props => theme.mainBrand});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

class HeroMiniSkills extends React.Component {
    render() {
        return (
            <Container1>
                <Virtcentered>
                    <LeftRight>
                        <ShowOnDesktop><br/><br/>
                            <div><DesktopH1>Jonathan Leahy</DesktopH1></div>
                        </ShowOnDesktop>
                        <MobileH1>Jon Leahy<h4>Full Stack Developer</h4></MobileH1>
                        <ShowOnDesktop><H4>JavaScript, TypeScript, React, Node, PHP, Laravel & Vue</H4></ShowOnDesktop>
                        <ShowOnDesktop><H4>Full Stack Developer, Bristol & Remote</H4></ShowOnDesktop>
                    </LeftRight>
                </Virtcentered>
            </Container1>
        );
    }
}

export default HeroMiniSkills;
