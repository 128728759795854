import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    width: 900px;
    @media (max-width: 1050px) {
        width: 85%;
    }
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 0 0;
    padding: 0 0 0 0 ;
`;

class MainColumnWidthContainer extends React.Component {
    render() {
        return (
            <StyledContainer>
                {this.props.children}
            </StyledContainer>
        );
    }
}

export default MainColumnWidthContainer;
