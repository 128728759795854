import React from 'react';
import Footer from "../containers/Footer";
import posed from "react-pose";
import MasterSkills from "../containers/MasterSkills";
import Intro from "../containers/Intro";
import Intro1 from "../containers/Intro1";
import HeroMini from "../containers/HeroMini";

const Top = posed.div({
    enter: { x: 0, opacity: 1, delay: 0 },
    exit: {
        x: -150,
        opacity: 0,
        transition: { duration: 100 }
    }
});

class Index extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            scrollTop: 0
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.scrollTop !== 0) {
            this.setState({scrollTop: nextProps.scrollTop});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {scrollTop} = this.state;
        if (scrollTop !== prevState.scrollTop) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return (
            <Top>
                <HeroMini/>
                <br/>
                <br/>
                <br/>
                <MasterSkills/>
                <Intro1/>
                <Intro/>
                <Footer/>
            </Top>
        )
    }
}

export default Index;
