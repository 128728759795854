import React from 'react';
import styled from 'styled-components';
import Footer from "../containers/Footer";
import posed from "react-pose";
import BrowserWidthContainer from "../containers/BrowserWidthContainer";
import MainColumnWidthContainer from "../containers/MainColumnWidthContainer";
import AuthHelperMethods from "../components/AuthHelperMethods";
import {prodDev} from "../library/prodDev";
import NTWys2 from "../plugins/NTWys2/Components/NTWys2";
import MonitorClick from "../common/MonitorClick";
import StickyBox from "react-sticky-box";
import Button from "../common/Button";

const Top = posed.div({
    enter: {x: 0, opacity: 1, delay: 0},
    exit: {
        x: 150,
        opacity: 0,
        transition: {duration: 100}
    }
});

const Nostyle = styled.a`
    text-decoration: none;
`;

const Center = styled.h1`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 700px) {
        width: 100%;
        flex-direction: column-reverse;
    }
`;

const LeftEntry = styled.div`
    z-index: -9;
`;

class Resume extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            isMounted: false,
            scrollTop: 0,
            currentTasks: {},
            page: ''
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.scrollTop !== 0) {
            this.setState({scrollTop: nextProps.scrollTop});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {scrollTop} = this.state;
        if (scrollTop !== prevState.scrollTop) {
            window.scrollTo(0, 0)
        }
    }

    async componentDidMount() {
        this.setState({
            isMounted: true,
        })

        try {
            let pagename = "cv"
            let Auth = new AuthHelperMethods();
            let endURL = prodDev.getUrl()
            //c1onsole.log(endURL + `/portfolio/${pagename}`);
            const response = await Auth.fetch(endURL + `/portfolio/${pagename}`)
            const json = await response.data;
            const page = `page/${response.page}`;
            if (this.state.isMounted) {
                this.setState({currentTasks: json, page: page});
            }
        } catch (e) {
            //c1onsole.log("no")
            //c1onsole.log("no" + e)
        }
    }

    componentWillUnmount() {
        this.setState({
            isMounted: false,
        })
    }

    getPageName() {
        let pagename = "resume"
        if (this.props.match.params.pagename) {
            pagename = this.props.match.params.pagename
        }
        return pagename
    }

    getPageNameIncDirectory() {
        return "http://localhost:3000/getcss/" + this.getPageName()
    }

    render() {
        return (
            <Top>
                { /* <link rel="stylesheet" href={this.getPageNameIncDirectory()} /> */}
                {/*    <br/>*/}
                {/*    <br/>*/}
                <MonitorClick
                    page={this.state.page}
                />
                <StickyBox offsetTop={80}>
                    <Center>
                        <LeftEntry>
                            <Nostyle href="/cv.pdf" target="_blank">
                                <Button imgClass="fal fa-2x fa-file-pdf">
                                    &nbsp;&nbsp;&nbsp;Download Resume
                                </Button>
                            </Nostyle>
                        </LeftEntry>
                    </Center>
                </StickyBox>

                {JSON.stringify(this.state.currentTasks).length < 20 &&
                    <BrowserWidthContainer>
                        <InputContainer>
                            <LeftEntry>
                                <Center>
                                    <div>
                                        <br/>
                                        <br/>
                                        <h3>Loading, Please Wait...</h3>
                                    </div>
                                    <div>
                                        <i className="far fa-duotone fa-spinner fa-spin"></i>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </div>
                                </Center>
                            </LeftEntry>
                        </InputContainer>
                    </BrowserWidthContainer>
                }

                {JSON.stringify(this.state.currentTasks).length > 20 &&
                    <BrowserWidthContainer>
                        <MainColumnWidthContainer>
                            <InputContainer>
                                <LeftEntry>
                                    <div>
                                        <NTWys2 showEditor={false}
                                                bImagesOnly={true} currentTsks={this.state.currentTasks}/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </div>
                                </LeftEntry>
                            </InputContainer>
                        </MainColumnWidthContainer>
                    </BrowserWidthContainer>
                }

                {JSON.stringify(this.state.currentTasks).length >= 20 &&
                    < Footer/>
                }

            </Top>
        );
    }

}

export default Resume;
