import React from 'react';
import posed from "react-pose";
import AuthHelperMethods from "../components/AuthHelperMethods";
import {prodDev} from "../library/prodDev";
import BrowserWidthContainer from "../containers/BrowserWidthContainer";

const Top = posed.div({
    enter: {x: 0, opacity: 1, delay: 0},
    exit: {
        x: 150,
        opacity: 0,
        transition: {duration: 100}
    }
});

class NoPage extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            isMounted: false,
            scrollTop: 0,
            currentTasks: {}
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.scrollTop !== 0) {
            this.setState({scrollTop: nextProps.scrollTop});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {scrollTop} = this.state;
        if (scrollTop !== prevState.scrollTop) {
            window.scrollTo(0, 0)
        }
    }

    async componentDidMount() {
        this.setState({
            isMounted: true,
        })

        try {
            let pagename = "cv"
            let Auth = new AuthHelperMethods();
            let endURL = prodDev.getUrl()
            //c1onsole.log(endURL + `/portfolio/${pagename}`);
            const response = await Auth.fetch(endURL + `/portfolio/${pagename}`)
            const json = await response.data;
            if (this.state.isMounted) {
                this.setState({currentTasks: json});
            }
        } catch (e) {
            //c1onsole.log("no")
            //c1onsole.log("no" + e)
        }
    }

    async printOutPage(pagename) {
        // try {
        //     c1onsole.log("ok")
        //
        //     axios({
        //         url: prodDev.getUrl() + "/cv",
        //         // url: prodDev.getUrl() + "/pdfpage/" + pagename,
        //         method: 'post',
        //         responseType: 'blob',
        //         data: {
        //             projectId: "this.state.Project.id"
        //         }
        //     }).then((response) => {
        //         const url = window.URL.createObjectURL(new Blob([response.data]));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', 'report.pdf');
        //         document.body.appendChild(link);
        //         link.click();
        //     });
        //
        // } catch (e) {
        //     c1onsole.log("no" + e)
        // }
    }

    componentWillUnmount() {
        this.setState({
            isMounted: false,
        })
    }

    getPageName() {
        let pagename = "resume"
        if (this.props.match.params.pagename) {
            pagename = this.props.match.params.pagename
        }
        return pagename
    }

    getPageNameIncDirectory() {
        return "http://localhost:3000/getcss/" + this.getPageName()
    }

    render() {
        return (
            <Top><br/><br/><br/><br/>
                <BrowserWidthContainer>
                    <i className="fas fa-6x fa-door-closed" alt="Tick-mark"/>
                    <h1>404: Page Not Found :-(</h1>
                </BrowserWidthContainer>
            </Top>
        )
    }
}

export default NoPage;
