import React from 'react';
import styled from 'styled-components';
import Footer from "../containers/Footer";
import posed from "react-pose";
import BrowserWidthContainer from "../containers/BrowserWidthContainer";
import MainColumnWidthContainer from "../containers/MainColumnWidthContainer";
import AuthHelperMethods from "../components/AuthHelperMethods";
import {prodDev} from "../library/prodDev";
import NTWys2 from "../plugins/NTWys2/Components/NTWys2";

const Top = posed.div({
    enter: {staggerChildren: 50}
});

const InputContainerTop = styled.div`
    margin: 0rem 0 2rem 0;
    
    display: flex;
    flex-direction: row;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    
    justify-content: space-between;
`;

const Center = styled.h1`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

class Ability extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            isMounted: false,
            scrollTop: 0,
            currentTasks: {}
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.scrollTop !== 0) {
            this.setState({scrollTop: nextProps.scrollTop});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {scrollTop} = this.state;
        if (scrollTop !== prevState.scrollTop) {
            window.scrollTo(0, 0)
        }
    }

    async componentDidMount() {
        this.setState({
            isMounted: true,
        })

        try {
            let pagename = "ability"
            //c1onsole.log("a1")
            let Auth = new AuthHelperMethods();
            let endURL = prodDev.getUrl()
            //c1onsole.log(endURL + `/portfolio/${pagename}`);
            const response = await Auth.fetch(endURL + `/portfolio/${pagename}`)
            const json = await response.data;
            if (this.state.isMounted) {
                this.setState({currentTasks: json});
            }
        } catch (e) {
            //c1onsole.log("no")
            //c1onsole.log("no" + e)
        }
    }

    async printOutPage(pagename) {
        // try {
        //     c1onsole.log("ok")
        //
        //     axios({
        //         url: prodDev.getUrl() + "/cv",
        //         // url: prodDev.getUrl() + "/pdfpage/" + pagename,
        //         method: 'post',
        //         responseType: 'blob',
        //         data: {
        //             projectId: "this.state.Project.id"
        //         }
        //     }).then((response) => {
        //         const url = window.URL.createObjectURL(new Blob([response.data]));
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.setAttribute('download', 'report.pdf');
        //         document.body.appendChild(link);
        //         link.click();
        //     });
        //
        // } catch (e) {
        //     c1onsole.log("no" + e)
        // }
    }

    componentWillUnmount() {
        this.setState({
            isMounted: false,
        })
        //c1onsole.log("willunmount")
    }

    getPageName() {
        let pagename = "ability"
        if (this.props.match.params.pagename) {
            pagename = this.props.match.params.pagename
        }
        return pagename
    }

    getPageNameIncDirectory() {
        return "http://localhost:3000/getcss/" + this.getPageName()
    }

    render() {

        return (

            <Top>

                { /* <link rel="stylesheet" href={this.getPageNameIncDirectory()} /> */}

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        <InputContainerTop>
                        </InputContainerTop>
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

                <BrowserWidthContainer>
                    <MainColumnWidthContainer>
                        {JSON.stringify(this.state.currentTasks).length < 20 &&
                            <Center>
                                <div>
                                    <br/>
                                    <br/>
                                    <h3>Loading, Please Wait...</h3>
                                </div>
                                <div>
                                    <i className="far fa-duotone fa-spinner fa-spin"></i>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </Center>
                        }
                        {JSON.stringify(this.state.currentTasks).length > 20 &&
                            <div>
                                <NTWys2 showEditor={false}
                                        bImagesOnly={true} currentTsks={this.state.currentTasks}/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        }
                    </MainColumnWidthContainer>
                </BrowserWidthContainer>

                {
                    JSON.stringify(this.state.currentTasks).length >= 20 &&
                    < Footer/>
                }

            </Top>

        );
    }

}

export default Ability;
