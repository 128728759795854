import React from 'react';
import styled from 'styled-components';
import HeaderMenuItems from "./HeaderMenuItems";

const Container = styled.div`
    z-index: 11;
    width: 100%;
    display: flex;
    background-color: ${props => props.sticky ? props.theme.transparent : props.theme.lightShade};
    justify-content: center;
    align-items: center;
    position: ${props => props.sticky ? 'absolute' : 'fixed'};
    top: 0rem;
    font-size: 1.2rem;
    visibility: ${props => props.sticky ? '' : 'hidden'};
    @media (max-width: 900px) {
        display: none;
    }
    height: ${props => props.sticky ? `5rem` : `0rem`};
    transition: all .1s ease-in;
    border-bottom: ${props => props.sticky ? `` : `1px solid`};
    border-bottom-color: ${props => props.sticky ? `` : props.theme.lightAccent};
    
`;

const MenuA = styled.nav`
    width: 900px;
    font-size: 1.2rem;
    @media (max-width: 900px) {
        width: 700px;
        font-size: 1rem;
    }
    justify-content: space-between;
`;


class HeaderBar extends React.Component {

    showSettings = () => {
    }

    render() {
        const sticky = this.props.sticky;
        return (
            <Container id='header' sticky={sticky}>
                <MenuA>
                    <HeaderMenuItems onClick={this.showSettings}/>
                </MenuA>
            </Container>
        );
    }
}

export default HeaderBar;
