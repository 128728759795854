import React from 'react';
import styled from 'styled-components';
import BrowserWidthContainerDark from "./BrowserWidthContainerDark";
import posed from "react-pose";
import {theme} from "../theme";

const H1 = styled.h1`
   font-weight: 700;
   color: ${props => props.theme.darkShades};
   font-size: 4.8rem;
   line-height: 6rem;
   
   @media (max-width: 900px) {
    font-size: 3rem;
    line-height: 4rem;
    padding: 2rem 0 0rem 0;
   }
   
  background-image: linear-gradient(to left, ${props => theme.black}, ${props => theme.black}, ${props => theme.black}, ${props => theme.black}, ${props => theme.darkAccent}, ${props => theme.darkAccent}, ${props => theme.mainBrand});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const H2 = styled.h2`
    line-height: 2.2rem;
    font-size: 1.6rem;
    color: ${props => props.theme.darkShades};
    margin: 1rem 1rem 1rem 1rem;

    position: relative;
    
    @media (max-width: 900px) {
    font-size: 1.3rem;
    line-height: 1.5rem;
    letter-spacing: .1rem;;
    }    

  background-image: linear-gradient(to right, ${props => theme.black}, ${props => theme.black}, ${props => theme.black}, ${props => theme.black}, ${props => theme.darkAccent}, ${props => theme.darkAccent}, ${props => theme.mainBrand});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

`;

const LeftRight = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    text-align: center;
     
    h1, h2, h3, h4, h5, h6 {
      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }

    h1 {
      margin-top: 4rem;
      margin-bottom: 1.5rem;
    }

    @media (max-width: 900px) {
        height: 80vh;
    }
`;

const Tops = posed.div({
    enter: {x: 0, opacity: 1, delay: 0},
    exit: {
        x: +750,
        opacity: 0,
        transition: {duration: 100}
    }
});

class HeroMini extends React.Component {
    render() {
        return (
            <BrowserWidthContainerDark img={'/StockSnap_P3L3YBQHGI_low.jpg'} showBounce={true}
                                       scroll={true}>
                <Tops>
                    <LeftRight>
                        <p></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <H1 className="rainbow-text">Jonathan Leahy</H1>
                        <H2>JavaScript, TypeScript, React & Node</H2>
                        <H2>PHP, Laravel & Vue</H2>
                        <H2>Full Stack Software Developer, Bristol & Remote</H2>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </LeftRight>
                </Tops>
            </BrowserWidthContainerDark>
        );
    }
}

export default HeroMini;
