import React, { Fragment } from 'react';
import styled from 'styled-components';

const Hover = styled.div`
    opacity: 0;
    transition: all 350ms ease;
`;

const DisplayOver = styled.div`
    height: 100%;
    left: 0;
    position: absolute;    
    top: 0;
    width: 100%;
    z-index: 2;
    transition: all 650ms ease;
    background-color: transparent;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
`;

const BigTitle = styled.h2`
    text-transform: uppercase;
    font-family: Helvetica;
    transition: all 650ms ease;
    text-shadow: 0 0 5px gray;
`;

const SubTitle = styled.h4`
    font-family: Helvetica;
    transform: translate3d(0,50px,0);
    transition: all  650ms ease;
`;

const Paragraph = styled.p`
    transform: translate3d(0,50px,0);
    transition: all  650ms ease;
`;

const Background = styled.div`

    position: relative;
    background-repeat: no-repeat;
    color: #FFF;

    margin: 1rem 1rem;
    
    top: 0;
    left: 0;
    width: calc(100% - 3rem);
    height: calc(100% - 3rem);

    cursor: pointer;
    background-image: url(${props => props.img !== "" ? props.img : ""} );

    background-size: ${props => props.backgroundsize ? props.backgroundsize : "cover"};
    background-position: center center;

    &:hover ${BigTitle} {
        opacity: 0;
        visibility: hidden;
        height: 0px;
    }

    &:hover ${DisplayOver} {
        background-color: rgba(0,0,0,.5);
    }

    &:hover ${SubTitle}, :hover ${Paragraph} {
        transform: translate3d(0,0,0);
    }

    &:hover ${Hover} {
        opacity: 1;
    }
    
`;

class InfoCard extends React.Component {

    render() {
        const title = this.props.title;
        const img = this.props.img;
        const backgroundsize = this.props.backgroundsize;
        return (
            <Fragment>
                <Background imga={img} backgroundsize={backgroundsize}>
                    <DisplayOver>
                        <BigTitle>{title}</BigTitle>
                        <Hover>
                            {/*<SubTitle>Hello!</SubTitle>*/}
                            <Paragraph>
                            </Paragraph>
                        </Hover>
                    </DisplayOver>
                </Background>
            </Fragment>
        );
    }
}

export default InfoCard;
