export class prodDev {

    static getUrl() {
        let endURL = "https://api.jonathanleahy.co.uk";
        if (process.env.NODE_ENV !== 'production') {
            endURL = "http://localhost:3000";
        }
        return endURL
    }

    static getUrlWys() {
        let endURL = "https://wyseditor.jonathanleahy.co.uk";
        if (process.env.NODE_ENV !== 'production') {
            endURL = "http://localhost:3002";
        }
        return endURL
    }

}
