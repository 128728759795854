export function populateData() {

    return [

        {title: "JavaScript", rating: 4, img: "/js.png", level: 1, categories: ["JAVASCRIPT", "LANG", "SMART", "GENERAL", "CORE"] },
        {title: "ES6", rating: 3.5, img: "", level: 1, categories: ["JAVASCRIPT", "LANG", "GENERAL", "CORE"]},
        {title: "React", rating: 4, img: "/React.js_logo-512.png", level: 1, categories: ["JAVASCRIPT", "DESIGN", "GENERAL", "CORE"]},
        {title: "Node", rating: 3.5, img: "", level: 1, categories: ["JAVASCRIPT", "BACKEND", "GENERAL", "CORE"]},
        {title: "Typescript", rating: 3.5, img: "", level: 1, categories: ["JAVASCRIPT", "LANG", "GENERAL", "CORE"]},
        {title: "PHP", rating: 5, img: "/php.png", level: 1, categories: ["PHP", "LANG", "BACKEND", "SMART", "GENERAL", "CORE"]},
        {title: "Laravel", rating: 4, img: "/laravel.png", level: 1, categories: ["PHP", "SMART", "GENERAL", "CORE"]},
        {title: "MySQL", rating: 4, img: "", level: 1, categories: ["JAVASCRIPT", "PHP", "DB", "SMART", "GENERAL", "CORE"]},

        {title: "React-Redux", rating: 3.5, img: "", level: 1, categories: ["JAVASCRIPT", "OTHERGENERAL", "CORE"]},
        {title: "React Native", rating: 3, img: "", level: 1, categories: ["JAVASCRIPT", "OTHERGENERAL"]},
        {title: "Vue", rating: 3.5, img: "", level: 1, categories: ["JAVASCRIPT", "VUE", "PHP", "SMART", "OTHERGENERAL"]},

        {title: "Bootstrap", rating: 4, img: "", level: 1, categories: ["JAVASCRIPT", "DESIGN", "SMART", "OTHERGENERAL", "CORE"]},
        {title: "Express", rating: 3.5, img: "", level: 1, categories: ["JAVASCRIPT", "OTHERGENERAL", "CORE"]},
        {title: "MongoDB", rating: 2.5, img: "", level: 1, categories: ["JAVASCRIPT", "HAVEUSED"]},
        {title: "Apollo", rating: 2.5, img: "", level: 2, categories: ["JAVASCRIPT", "DB", "HAVEUSED"]},
        {title: "Firestore", rating: 2, img: "", level: 3, categories: ["JAVASCRIPT", "DB", "HAVEUSED"]},
        {title: "Prisma", rating: 2.5, img: "", level: 2, categories: ["JAVASCRIPT", "DB", "HAVEUSED"]},
        {title: "MsSQL", rating: 3, img: "", level: 1, categories: ["JAVASCRIPT", "PHP", "DB", "SMART", "OTHEREGENERAL"]},
        {title: "GraphQL", rating: 3, img: "", level: 2, categories: ["JAVASCRIPT", "DB", "OTHERGENERAL"]},
        {title: "Next (SSR)", rating: 3, img: "", level: 2, categories: ["JAVASCRIPT", "SEO", "HAVEUSED"]},
        {title: "NPM", rating: 3.5, img: "", level: 1, categories: ["JAVASCRIPT", "OTHERGENERAL"]},
        {title: "TDD",rating: 3, img: "",level: 2,categories: ["JAVASCRIPT", "PHP", "TESTING", "OTHER", "SMART", "OTHERGENERAL"]},
        {title: "Design Patterns", rating: 3, img: "", level: 0, categories: ["ARCH", "OTHER", "SMART", "OTHERGENERAL", "CORE"]},
        {title: "HTML5", rating: 4, img: "", level: 1, categories: ["JAVASCRIPT", "PHP", "DESIGN", "SMART", "GENERAL", "CORE"]},
        {title: "Gulp", rating: 3.5, img: "", level: 1, categories: ["JAVASCRIPT", "PHP", "BUILD", "SMART", "OTHEREGENERAL" ]},
        {title: "CSS3", rating: 4, img: "", level: 1, categories: ["JAVASCRIPT", "PHP", "DESIGN", "SMART", "GENERAL", "CORE"]},

        {title: "Rest", rating: 4, img: "", level: 1, categories: ["JAVASCRIPT", "PHP", "SMART", "GENERAL", "CORE"]},

        {title: "Architecture", rating: 3.5, img: "", level: 1, categories: ["ARCH", "SMART", "OTHERGENERAL"]},
        {title: "Documentation", rating: 4, img: "", level: 0, categories: ["ARCH", "SMART", "OTHERGENERAL", "CORE"]},
        {title: "Object-Oriented", rating: 4.5, img: "", level: 0, categories: ["ARCH","SMART", "GENERAL", "CORE"]},
        {title: "Model Driven Design", rating: 3, img: "", level: 0, categories: ["ARCH", "OTHERGENERAL"]},
        {title: "Clean Architecture", rating: 3, img: "", level: 0, categories: ["ARCH", "OTHERGENERAL"]},
        {title: "Software Design", rating: 4.5, img: "", level: 0, categories: ["ARCH", "SMART", "GENERAL", "CORE"]},

        {title: "PHPUnit", rating: 4, img: "", level: 1, categories: ["PHP", "TESTING", "SMART", "OTHERGENERAL, \"CORE\""]},

        {title: "Agile", rating: 3.5, img: "", level: 0, categories: ["PROCESS","SMART", "GENERAL", "CORE"]},
        {title: "Scrum", rating: 3.5, img: "", level: 1, categories: ["PROCESS", "OTHERGENERAL", "CORE"]},
        {title: "Kanban", rating: 3.5, img: "", level: 1, categories: ["PROCESS", "SMART", "OTHERGENERAL", "CORE"]},
        {title: "Waterfall", rating: 3, img: "", level: 1, categories: ["PROCESS"]},

        {title: "GIT", rating: 3, img: "", level: 1, categories: ["PROCESS", "SMART", "OTHERGENERAL"]},

        {title: "Composer", rating: 3, img: "", level: 1, categories: ["PHP", "SMART", "OTHEREUSED"]},

        {title: "Unit Tests", rating: 3.5, img: "", level: 1, categories: ["PHP", "TESTING", "SMART", "OTHERGENERAL", "CORE"]},
        {title: "Intergration Tests", rating: 3.5, img: "", level: 2, categories: ["JAVASCRIPT", "PHP", "TESTING", "SMART", "OTHERGENERAL"]},
        {title: "Functional Tests", rating: 3.5, img: "", level: 2, categories: ["JAVASCRIPT", "PHP", "TESTING", "SMART", "OTHERGENERAL"]},
        {title: "e2e Tests", rating: 3.5, img: "", level: 2, categories: ["JAVASCRIPT", "PHP", "TESTING", "SMART", "OTHERGENERAL"]},
        {title: "Storybook", rating: 3.5, img: "", level: 2, categories: ["JAVASCRIPT", "TESTING", "OTHERGENERAL"]},

        {title: "Responsive Web Design", rating: 4, img: "", level: 1, categories: ["JAVASCRIPT", "PHP", "DESIGN", "OTHEREGENERAL", "CORE"]},
        {title: "Flexbox", rating: 3.5, img: "", level: 2, categories: ["JAVASCRIPT", "PHP", "DESIGN", "OTHERGENERAL", "CORE"]},
        {title: "CSS Grid", rating: 3.5, img: "", level: 2, categories: ["JAVASCRIPT", "PHP", "DESIGN", "OTHERGENERAL"]},
        {title: "SASS", rating: 3.5, img: "", level: 2, categories: ["JAVASCRIPT", "PHP", "DESIGN", "OTHEREGENERAL", "CORE"]},
        {title: "SCSS", rating: 3.5, img: "", level: 1, categories: ["JAVASCRIPT", "PHP", "DESIGN", "OTHEREGENERAL"]},
        {title: "jQuery", rating: 3, img: "", level: 1, categories: ["JAVASCRIPT", "PHP", "DESIGN", "SMART", "OTHEREGENERAL"]},
        {title: "Canvas", rating: 3.5, img: "", level: 1, categories: ["JAVASCRIPT", "PHP", "HAVEUSED"]},
        {title: "Webpack", rating: 4, img: "", level: 1, categories: ["JAVASCRIPT", "BUILD", "GENERAL", "CORE"]},
        {title: "Jenkins", rating: 3, img: "", level: 1, categories: ["SERVER", "BUILD", "OTHERGENERAL", "CORE"]},
        {title: "Docker", rating: 3, img: "", level: 1, categories: ["SERVER", "BUILD", "DEPLOYMENT", "OTHERGENERAL", "CORE"]},
        {title: "UML", rating: 2.5, img: "", level: 1, categories: ["ARCH", "HAVEUSED"]},
        {title: "Ubuntu", rating: 4.5, img: "", level: 1, categories: ["SERVER", "SMART", "GENERAL", "CORE"]},
        {title: "Apache", rating: 4, img: "", level: 1, categories: ["SERVER", "OTHEREGENERAL"]},
        {title: "NGINX", rating: 4, img: "", level: 1, categories: ["SERVER", "SMART", "OTHEREGENERAL", "CORE"]},
        {title: "Ansible", rating: 3, img: "", level: 1, categories: ["SERVER", "BUILD", "OTHEREGENERAL, \"CORE\""]},
        {title: "Vagrant", rating: 2.5, img: "", level: 1, categories: ["SERVER", "BUILD", "HAVEUSED"]},
        {title: "Visual Basic", rating: 4, img: "", level: 1, categories: ["Lang"]},
        {title: "C#", rating: 3, img: "", level: 1, categories: ["LANG", "HAVEUSED"]},
        {title: "Java", rating: 3, img: "", level: 1, categories: ["LANG", "HAVEUSED"]},
        {title: "Python", rating: 2.5, img: "", level: 1, categories: ["LANG", "HAVEUSED"]},
        {title: "Delphi", rating: 3, img: "", level: 1, categories: ["LANG", "HAVEUSED"]},
        {title: "MVP", rating: 2.5, img: "", level: 0, categories: ["PROCESS", "HAVEUSED"]},

        // {title: "Firefox / Chrome Extensions", rating: 2.5, img: "", level: 2, categories: ["JAVASCRIPT", "OTHERGENERAL"]},

    ];

}
