import React from 'react';
import { connect } from 'react-redux';
import { closeModal } from './modalActions'
import Modal from 'react-modal';
import styled from "styled-components";
import ButtonSimple from "../../common/ButtonSimple";

const Padded = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: 40%;
`;

const Across = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: 500px;
`;

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

const actions = {
    closeModal
}

class MoreDetails1Modal extends React.Component {

    constructor() {
        super();

        this.state = {
            modalIsOpen: true
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    UNSAFE_componentWillMount() {
        Modal.setAppElement('body');
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.props.closeModal()
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="More Details1"
                >

                    <Across>
                        <Padded src={"/locked1.svg"} width={'75px'}></Padded>
                        <div>
                            <h2 ref={subtitle => this.subtitle = subtitle}>Access Restricted</h2>
                            <p>Please Sign In to view this content<br/></p>
                            <p>
                        <ButtonSimple width={'auto'} onClick={
                            this.closeModal
                        }>OK</ButtonSimple>
                        </p>
                        </div>

                        {/*<Padded src={"/presentation.svg"} width={'75px'}></Padded>*/}
                    </Across>

                </Modal>
            </div>
        );
    }

};

export default connect(null,actions)(MoreDetails1Modal);