import styled from 'styled-components';
import {theme} from "../theme";

const ButtonClearLight = styled.button`

    text-decoration: none;
    border-radius: 4px;
    color: ${ $props => theme.black };
    /* transition: all .6s ease-in-out; */
    position: relative;
    text-align: center;
    background: transparent ;
    
    :hover {
        text-decoration: none;
        background-color: ${ props => props.theme.lightAccent};
        color: ${ props => props.theme.darkShades};
        :before {
                transform: unset;
        }
        :after {
                transform: unset;
        }
    }
    
    width: calc(10% + 4rem);
    @media (max-width: 900px) {
        width: 100%;
    }

    margin: 1rem .2rem 1rem .2rem;
    padding: .5rem 1rem .5rem 1rem;

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 4px;
        width: calc(100% - 8px);
        height: 2px;
        background-color: ${ props => props.theme.lightAccent};
        transform: scaleX(0);
        transition: all .3s ease-in-out;
        transform-origin: left;
    }
    
    :after {
        content: '';
        position: absolute;
        top: calc(100% - 3px);
        right: 4px;
        width: calc(100% - 8px);
        height: 2px;
        background-color: ${ props => props.theme.lightAccent};
        transform: scaleX(0);
        transition: all .3s ease-in-out;
        transform-origin: right;
    }
   
`;

export default ButtonClearLight;